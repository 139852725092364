<template>
  <div class="permission">
    <router-view v-if="$route.name !== 'Permission'" />
    <div v-else>
      <v-row class="mb-4">
        <v-col
          cols="12"
          md="6">
          <h1> {{ $t('app.page.permission.title') }} </h1>
        </v-col>
        <v-col
          cols="12"
          md="2">
          <v-text-field
            :value="search"
            :placeholder="$t('app.placeholder.search')"
            prepend-inner-icon="mdi-magnify"
            dense
            outlined
            hide-details
            @input="debounceSearching($event)" />
        </v-col>
        <v-col
          cols="12"
          md="2">
          <v-select
            v-model="selectedFilter"
            :placeholder="$t('app.placeholder.filter')"
            :items="filters"
            item-text="text"
            item-value="value"
            clearable
            dense
            outlined
            hide-details />
        </v-col>
        <v-col
          cols="12"
          md="2">
          <div class="text-right">
            <v-btn
              color="primary"
              depressed
              @click="setDialog(true)">
              {{ $t('app.btn.invite') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="isLoading"
        :loading-text="$t('vuetify.loading')"
        :no-data-text="$t('vuetify.noData')"
        hide-default-footer>
        <template v-slot:item.name="{ item }">
          {{ item.firstName }} {{ item.lastName }}
        </template>
        <template v-slot:item.category="{ item }">
          <div v-if="item.category">
            {{ item.category }}
          </div>
          <div
            v-else
            class="text-center">
            -
          </div>
        </template>
        <template v-slot:item.category="{ item }">
          <div
            v-if="categorys(item).length"
            class="d-flex">
            <template v-for="(category, i) in categorys(item)">
              <div :key="`admin-category-${i}`">
                {{ category }}
              </div>
              <div
                v-if="i < categorys(item).length - 1"
                :key="`admin-and-${i}`"
                class="mr-2">
                ,
              </div>
            </template>
          </div>
          <div
            v-else
            class="text-center">
            -
          </div>
        </template>
        <template v-slot:item.locations="{ item }">
          <v-tooltip
            v-if="item.locations.length"
            bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on">
                <span v-if="item.locations[0]">
                  {{ item.locations[0].name }}
                  <span v-if="item.locations.length > 1">
                    (+{{ item.locations.length - 1 }})
                  </span>
                </span>
              </div>
            </template>
            <div
              v-for="(location, i) in item.locations"
              :key="`admin-location-item-${i}`">
              {{ location.name }}
            </div>
          </v-tooltip>
          <div
            v-else
            class="text-center">
            -
          </div>
        </template>
        <template v-slot:item.options="{ item }">
          <div class="d-flex">
            <v-icon
              class="mr-2"
              @click="update(item.id)">
              mdi-square-edit-outline
            </v-icon>
            <v-icon
              v-if="user.meta.roleName === 'admin'"
              @click="remove(item.id)">
              mdi-delete-outline
            </v-icon>
          </div>
        </template>
      </v-data-table>
      <div class="d-flex justify-center justify-md-end mt-6">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="5" />
      </div>
    </div>
    <v-dialog
      v-model="dialog"
      :retain-focus="false"
      max-width="400">
      <div class="permission__card">
        <div class="d-flex mb-12">
          <v-select
            v-model="roleInvite"
            :placeholder="$t('app.placeholder.role')"
            :items="roles"
            item-text="name"
            item-value="id"
            class="mr-2"
            dense
            outlined
            hide-details />
          <v-btn
            :disabled="!roleInvite"
            color="primary"
            height="40"
            depressed
            @click="invite()">
            {{ $t('app.btn.link') }}
          </v-btn>
        </div>
        <div class="d-flex">
          <v-text-field
            v-model="linkInvite"
            append-outer-icon="mdi-content-copy"
            class="mr-2"
            readonly
            dense
            outlined
            hide-details
            @click:append-outer="copy()" />
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AdminProvider from '@/resources/axios/providers/admin'
import InvitationProvider from '@/resources/axios/providers/invitation'

export default {
  name: 'Permission',
  data () {
    return {
      page: 1,
      limit: 7,
      totalPage: 1,
      search: '',
      isLoading: false,
      dialog: false,
      headers: [
        { text: '#', sortable: false, value: 'id', align: 'center' },
        { text: this.$t('app.table.header.name'), sortable: false, value: 'name' },
        { text: this.$t('app.table.header.telephone'), sortable: false, value: 'phoneNo' },
        { text: this.$t('app.table.header.email'), sortable: false, value: 'email', align: 'center' },
        { text: this.$t('app.table.header.role'), sortable: false, value: 'roleName' },
        { text: this.$t('app.table.header.category'), sortable: false, value: 'category' },
        { text: this.$t('app.table.header.locations'), sortable: false, value: 'locations' },
        { text: '', sortable: false, value: 'options' }
      ],
      items: [],
      selectedFilter: null,
      filters: [
        {
          text: this.$t('app.select.username'),
          value: 'username'
        },
        {
          text: this.$t('app.select.email'),
          value: 'email'
        }
      ],
      roleInvite: null,
      linkInvite: ''
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      roles: 'local/roles'
    })
  },
  watch: {
    selectedFilter () {
      this.getAdmin()
    },
    page () {
      this.getAdmin()
    }
  },
  created () {
    this.debounceSearching = this.$_.debounce(this.setSearch, 800)
  },
  mounted () {
    if (this.$route.name === 'Permission') {
      this.getAdmin()
    }
  },
  methods: {
    ...mapActions({
      showErrorMessage: 'notification/showErrorMessage',
      closeErrorMessage: 'notification/closeErrorMessage'
    }),
    setDialog (value) {
      this.dialog = value
    },
    categorys (value) {
      const categorys = []
      if (value.locations?.some((item) => item.type === 'rescue')) {
        categorys.push('rescue')
      }
      if (value.locations?.some((item) => item.type === 'fire')) {
        categorys.push('fire')
      }
      if (value.locations?.some((item) => item.type === 'medical')) {
        categorys.push('medical')
      }
      return categorys
    },
    setSearch (search) {
      this.search = search
      this.getAdmin()
    },
    async getAdmin () {
      try {
        this.isLoading = true
        const res = await AdminProvider.getAdminList({
          page: this.page,
          limit: this.limit,
          filter: this.selectedFilter ? `&${this.selectedFilter}=${this.search}` : ''
        })
        if (res) {
          this.items = res.records
          this.totalPage = res.totalPage
          this.isLoading = false
        }
      } catch (error) {
        console.error(error)
      }
    },
    update (id) {
      this.$router.push({ name: 'PermissionUpdate', params: { permissionId: id } })
    },
    async copy () {
      const res = await this.$copyText(this.linkInvite)
      if (res) {
        this.showErrorMessage({
          message: this.$t('app.alert.copy'),
          type: 'success'
        })
        setTimeout(() => {
          this.closeErrorMessage()
        }, 2000)
      }
    },
    async invite () {
      try {
        const res = await InvitationProvider.createInvitation({
          roleId: this.roleInvite
        })
        if (res) {
          this.linkInvite = `${window.location.origin}/register?invite=${res.code}`
        }
      } catch (error) {
        console.error(error)
      }
    },
    async remove (id) {
      try {
        const res = await AdminProvider.deleteAdmin(id)
        if (res) {
          this.showErrorMessage({
            message: this.$t('app.alert.delete'),
            type: 'success'
          })
          setTimeout(() => {
            this.closeErrorMessage()
          }, 2000)
          this.getAdmin()
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style scoped>
  .permission {
    padding: 16px 24px;
  }
  .permission__card {
    padding: 24px;
    background-color: #FFFFFF;
    min-height: 200px;
  }
  .permission__truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
