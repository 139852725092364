import HttpRequest from '@/resources/axios/core/http-request'
import store from '@/store'

class InvitationProvider extends HttpRequest {
  createInvitation = (payload) => {
    this.setHeader({
      key: 'Authorization',
      value: `Bearer ${store.getters['auth/user'].accessToken}`
    })
    return this.post('/invitations', payload)
  }
}

export default new InvitationProvider()
