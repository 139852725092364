var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"permission"},[(_vm.$route.name !== 'Permission')?_c('router-view'):_c('div',[_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('app.page.permission.title'))+" ")])]),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"value":_vm.search,"placeholder":_vm.$t('app.placeholder.search'),"prepend-inner-icon":"mdi-magnify","dense":"","outlined":"","hide-details":""},on:{"input":function($event){return _vm.debounceSearching($event)}}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"placeholder":_vm.$t('app.placeholder.filter'),"items":_vm.filters,"item-text":"text","item-value":"value","clearable":"","dense":"","outlined":"","hide-details":""},model:{value:(_vm.selectedFilter),callback:function ($$v) {_vm.selectedFilter=$$v},expression:"selectedFilter"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.setDialog(true)}}},[_vm._v(" "+_vm._s(_vm.$t('app.btn.invite'))+" ")])],1)])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.isLoading,"loading-text":_vm.$t('vuetify.loading'),"no-data-text":_vm.$t('vuetify.noData'),"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [(_vm.categorys(item).length)?_c('div',{staticClass:"d-flex"},[_vm._l((_vm.categorys(item)),function(category,i){return [_c('div',{key:("admin-category-" + i)},[_vm._v(" "+_vm._s(category)+" ")]),(i < _vm.categorys(item).length - 1)?_c('div',{key:("admin-and-" + i),staticClass:"mr-2"},[_vm._v(" , ")]):_vm._e()]})],2):_c('div',{staticClass:"text-center"},[_vm._v(" - ")])]}},{key:"item.locations",fn:function(ref){
var item = ref.item;
return [(item.locations.length)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(item.locations[0])?_c('span',[_vm._v(" "+_vm._s(item.locations[0].name)+" "),(item.locations.length > 1)?_c('span',[_vm._v(" (+"+_vm._s(item.locations.length - 1)+") ")]):_vm._e()]):_vm._e()])]}}],null,true)},_vm._l((item.locations),function(location,i){return _c('div',{key:("admin-location-item-" + i)},[_vm._v(" "+_vm._s(location.name)+" ")])}),0):_c('div',{staticClass:"text-center"},[_vm._v(" - ")])]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.update(item.id)}}},[_vm._v(" mdi-square-edit-outline ")]),(_vm.user.meta.roleName === 'admin')?_c('v-icon',{on:{"click":function($event){return _vm.remove(item.id)}}},[_vm._v(" mdi-delete-outline ")]):_vm._e()],1)]}}])}),_c('div',{staticClass:"d-flex justify-center justify-md-end mt-6"},[_c('v-pagination',{attrs:{"length":_vm.totalPage,"total-visible":5},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1),_c('v-dialog',{attrs:{"retain-focus":false,"max-width":"400"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"permission__card"},[_c('div',{staticClass:"d-flex mb-12"},[_c('v-select',{staticClass:"mr-2",attrs:{"placeholder":_vm.$t('app.placeholder.role'),"items":_vm.roles,"item-text":"name","item-value":"id","dense":"","outlined":"","hide-details":""},model:{value:(_vm.roleInvite),callback:function ($$v) {_vm.roleInvite=$$v},expression:"roleInvite"}}),_c('v-btn',{attrs:{"disabled":!_vm.roleInvite,"color":"primary","height":"40","depressed":""},on:{"click":function($event){return _vm.invite()}}},[_vm._v(" "+_vm._s(_vm.$t('app.btn.link'))+" ")])],1),_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"mr-2",attrs:{"append-outer-icon":"mdi-content-copy","readonly":"","dense":"","outlined":"","hide-details":""},on:{"click:append-outer":function($event){return _vm.copy()}},model:{value:(_vm.linkInvite),callback:function ($$v) {_vm.linkInvite=$$v},expression:"linkInvite"}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }